import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'
import { grayscale, white } from 'src/styles/colors'

export const Section = styled.section`

`
export const Button = styled.button`

`

export const Card = styled.div`
  height: 276px;
  border: 1px solid ${grayscale[200]};
  background: ${white};
  padding: 26px 24px 64px;
  margin-right: 12px;
  border-radius: 16px;

  @media ${device.tablet} {
    p {
      font-size: 14px !important;
      line-height: 17px !important;
    }
  }

  .text-block {
    height: 100px;
  }

  @media ${device.tablet} {
    height: 276px;
    padding: 24px;
  }
`
