import React from 'react'
import Img from 'gatsby-image'
import pageQuery from '../../pageQuery'
import Icon from 'src/components/UI/MarkdownIcon'

import SectionComponent from 'src/components/SectionComponent'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import dataJson from './assets/data/data.json'

import { BlogCardsDiv, BlogCardTextContent, CategoryText, SubCategoryText, CategoriesDiv, BlogCard } from './style'

type BlogCardType = {
  image: string;
  category: string;
  subCategory?: string;
  title: string;
  description: string;
  date: string;
  link: string;
}

const QuerSaberMaisSobreDoutorInter = () => {
  const data = pageQuery()
  const [ sendDatalayerEvent ] = useDataLayer()
  const blogCard: BlogCardType[] = dataJson

  return (
    <SectionComponent
      id='conta-internacional-em-dolar'
      SectionStyles='py-5 bg-grayscale--100'
      minHeight={{ sm: 'auto' }}
    >
      <div className='col-12 text-center'>
        <h2 className='fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 fw-600 text-grayscale--500 pb-md-4 px-lg-5'>
          Quer saber mais sobre <span className='d-md-block d-lg-inline'>Doutor Inter <span className='d-xl-block'>e a telemedicina?</span></span>
        </h2>
        <p className='fs-14 lh-17 fs-lg-16 lh-lg-20 fs-xl-18 lh-xl-22 fw-400 text-grayscale--500 pb-md-4'>
          Selecionamos alguns posts do blog para você aprofundar <span className='d-block'>seus conhecimentos sobre o assunto.</span>
        </p>
      </div>
      <div className='col-12'>
        <BlogCardsDiv className='d-md-flex justify-content-md-center'>
          {blogCard.map((card: BlogCardType) => (
            <BlogCard
              href={card.link}
              key={card.image}
              target='_blank'
              rel='noreferrer'
              onClick={() => {
                sendDatalayerEvent({
                  section: 'dobra_5',
                  section_name: 'Quer saber mais sobre Doutor Inter e a telemedicina?',
                  element_action: 'click button',
                  element_name: card.title,
                  redirect_url: card.link,
                })
              }}
            >
              <div>
                <Img fluid={data[card.image].fluid} alt='Ilustração do post no blog Inter.' />
              </div>
              <BlogCardTextContent key={card.image}>
                <div>
                  <CategoriesDiv className='d-flex'>
                    <CategoryText className='fs-12 lh-15 fw-700 text-white mr-3' dangerouslySetInnerHTML={{ __html: card.category }} />
                    {card.subCategory && <SubCategoryText className='fs-12 lh-15 fw-700 text-grayscale--500 d-md-none' dangerouslySetInnerHTML={{ __html: card.subCategory }} />}
                  </CategoriesDiv>
                  <div className=''>
                    <h3 className='fs-20 lh-25 fw-600 text-grayscale--500 mb-2' dangerouslySetInnerHTML={{ __html: card.title }} />
                    <h6 className='fs-12 lh-15 fw-700 text-grayscale--300 mb-0' dangerouslySetInnerHTML={{ __html: card.date }} />
                  </div>
                  <div className='d-none d-lg-block'>
                    <p className='fs-16 lh-20 text-grayscale--500 mb-0 mt-lg-3' dangerouslySetInnerHTML={{ __html: card.description }} />
                    <div className='mt-2'>
                      <span
                        title='Leia mais'
                        className='fs-14 lh-17 fw-400 text-orange--extra text-right d-none d-lg-block'
                      >
                        Leia mais
                        <Icon width='22.5' height='24.34' color='orange--base ml-2' icon='navigation/arrow-right' directory='v2' />
                      </span>
                    </div>
                  </div>
                </div>
              </BlogCardTextContent>
            </BlogCard>
          ))}
        </BlogCardsDiv>
        <div className='col-12 pt-4' />
        <div className='text-center'>
          <a
            href='https://blog.inter.co/para-simplificar-a-vida/seguros/'
            className='btn btn--lg btn-orange--extra fs-14 lh-17 fw-700 rounded-2 text-none'
            target='_blank'
            rel='noreferrer'
            onClick={() => {
              sendDatalayerEvent({
                section: 'dobra_5',
                section_name: 'Quer saber mais sobre Doutor Inter e a telemedicina?',
                element_action: 'click button',
                element_name: 'Acessar mais artigos',
                redirect_url: 'https://blog.inter.co/para-simplificar-a-vida/seguros/',
              })
            }}
          >
            Acessar mais artigos
          </a>
        </div>
      </div>
    </SectionComponent>
  )
}

export default QuerSaberMaisSobreDoutorInter
