import { device } from 'src/styles/breakpoints'
import { grayscale, orange, white } from 'src/styles/colors'
import styled from 'styled-components'

export const BlogCardsDiv = styled.div`
  gap: 24px;

  .gatsby-image-wrapper {
    margin-bottom: 0 !important;
  }
`

export const BlogCard = styled.a`
  cursor: pointer;
  display: block;
  @media ${device.tablet} {
    width: 216px;
  }
  @media ${device.desktopLG} {
    width: 296px;
  }
  @media ${device.desktopXL} {
    width: 360px;
  }
`

export const BlogCardTextContent = styled.div`
  position: relative;
  top: -18px;
  background-color: ${white};
  padding: 18px 24px;
  border-radius: 16px 16px 0px 0px;
`

export const CategoryText = styled.h5`
  background-color: ${orange.extra};
  border-radius: 4px;
  padding: 4px;
`

export const SubCategoryText = styled.h5`
  background-color: ${grayscale[100]};
  border-radius: 4px;
  padding: 4px;
`

export const CategoriesDiv = styled.div`
  margin-bottom: 20px;
`
