import React from 'react'
import Img from 'gatsby-image'
import pageQuery from '../../pageQuery'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import SectionComponent from 'src/components/SectionComponent'

// Data Json
import dataJson from './assets/data/data.json'

// Styles
import { Card } from './style'

type CardsType = {
  image: string;
  altImage: string;
  title: string;
  description: string;
}

type MuitasVantagensParaVoceProps = {
  setIsOpen: Function;
};

const MuitasVantagensParaVoce = ({ setIsOpen }: MuitasVantagensParaVoceProps) => {
  const data = pageQuery()
  const [ sendDatalayerEvent ] = useDataLayer()
  const cards: CardsType[] = dataJson

  return (
    <SectionComponent
      id='o-inter-empresas'
      SectionStyles='py-5 bg-grayscale--100'
      RowStyles='align-items-md-start'
      minHeight={{ sm: 'auto' }}
    >
      <div className='col-12 text-center mb-5'>
        <h2 className='fs-24 lh-30 fs-lg-40 lh-lg-50 fw-600 text-grayscale--500 mb-4'><span className='d-block'>Doutor Inter:</span> muitas vantagens pra você!</h2>
        <p className='fs-14 lh-17 fs-lg-18 lh-lg-22 text-grayscale--500 mb-0'>Consultas 100% digitais, descontos em laboratórios, farmácias e muito mais.</p>
      </div>
      {cards.map((card: CardsType) => (
        <Card key={card.image} className='col-12 col-md-4'>
          <div className='card-div text-center'>
            <Img fluid={data[card.image].fluid} alt={card.altImage} className='mb-4 mx-auto' />
            <h3 className='fs-20 lh-25 fs-xl-24 lh-xl-30 fw-600 text-grayscale--500 mb-3' dangerouslySetInnerHTML={{ __html: card.title }} />
            <p className='fs-14 lh-17 fs-lg-18 lh-lg-22 fw-400 text-grayscale--400 mb-0' dangerouslySetInnerHTML={{ __html: card.description }} />
          </div>
        </Card>
      ))}
      <div className='col-12 mt-md-5 text-md-center'>
        <a
          title='Contratar Doutor Inter'
          className='btn btn--lg btn-orange--extra rounded-2 fs-14 fw-600 text-none mb-2 mb-xl-3 d-md-none'
          href='https://intergo.app/dea87a79'
          target='_blank'
          rel='noreferrer'
          onClick={() => sendDatalayerEvent({
            section: 'dobra_03',
            section_name: 'Doutor Inter: muitas vantagens pra você!',
            element_action: 'click button',
            element_name: 'Contratar Doutor Inter',
            redirect_url: 'https://intergo.app/dea87a79',
          })}
        >
          Contratar Doutor Inter
        </a>
        <a
          title='Contratar Doutor Inter'
          className='btn btn--lg btn-orange--extra rounded-2 fs-14 fw-600 text-none mb-2 mb-xl-3 d-none d-md-flex mx-auto'
          onClick={() => {
            setIsOpen(true)
            sendDatalayerEvent({
              section: 'dobra_03',
              section_name: 'Doutor Inter: muitas vantagens pra você!',
              element_action: 'click button',
              element_name: 'Contratar Doutor Inter',
            })
          }}
        >
          Contratar Doutor Inter
        </a>
      </div>
    </SectionComponent>
  )
}

export default MuitasVantagensParaVoce
