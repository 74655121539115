import styled, { css } from 'styled-components'
import { orange, white } from 'src/styles/colors'
import { device } from 'src/styles/breakpoints'

import backgroundDoutorInterConsultaOnlineeSM from '../../assets/images/background-hero-doutor-inter-consulta-online-sm.png'
import backgroundDoutorInterConsultaOnlineeMD from '../../assets/images/background-hero-doutor-inter-consulta-online-md.png'
import backgroundDoutorInterConsultaOnlineeLG from '../../assets/images/background-hero-doutor-inter-consulta-online-lg.png'
import backgroundDoutorInterConsultaOnlineeXL from '../../assets/images/background-hero-doutor-inter-consulta-online-xl.png'

const ButtonDefaultClasses = css`
  cursor: pointer;
  display: block;
  width: 100%;
  height: 48px;
  border-radius: 8px;
  transition: .3s;

  &:hover {
    opacity: 0.8;
  }
`

export const SectionWrapper = styled.section`
  background-image: url(${backgroundDoutorInterConsultaOnlineeSM});
  background-color: ${orange.extra};
  background-repeat: no-repeat;
  background-position: top -3px center;
  display: flex;
  min-height: 668px;
  background-size: contain;

  @media (min-width: 388px) {
    min-height: 800px;
  }

  @media ${device.tablet} {
    background-image: url(${backgroundDoutorInterConsultaOnlineeMD});
    background-position: center left;
    min-height: 537px;
    background-size: auto;
  }

  @media ${device.desktopLG} {
    background-image: url(${backgroundDoutorInterConsultaOnlineeLG});
    min-height: 545px;
  }

  @media ${device.desktopXL} {
    background-image: url(${backgroundDoutorInterConsultaOnlineeXL});
    min-height: 641;
  }

  .button-white {
    ${ButtonDefaultClasses}
    background-color: ${white};
  }

  .button-transparent {
    ${ButtonDefaultClasses}
    border: 1px solid #FFF;
  }
`
