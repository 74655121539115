import React, { ReactNode } from 'react'

// Icons
import GeneralPractitioner from 'inter-frontend-svgs/lib/orangeds/MD/general-practitioner'
import Doctor from 'inter-frontend-svgs/lib/orangeds/MD/doctor'
import Psychologist from 'inter-frontend-svgs/lib/orangeds/MD/psychologist'
import Nutritionist from 'inter-frontend-svgs/lib/orangeds/MD/nutritionist'

// Components
import DefaultCarousel from 'src/components/UI/Carousels/DefaultCarousel'

import dataJson from './assets/data/data.json'

import SectionComponent from 'src/components/SectionComponent'

// Styles
import { Card } from './style'
import { orange } from 'src/styles/colors'

type CardsType = {
  icon: string;
  title: string;
  description: string;
  priceDescription: string;
}

interface IIconsList {
  [index: string]: ReactNode;
}

const PlanoMensal = () => {
  const cards: CardsType[] = dataJson
  const icons: IIconsList = {
    generalPractitioner: <GeneralPractitioner color={orange.extra} width={42} height={42} />,
    doctor: <Doctor color={orange.extra} width={42} height={42} />,
    psychologist: <Psychologist color={orange.extra} width={42} height={42} />,
    nutritionist: <Nutritionist color={orange.extra} width={42} height={42} />,
  }
  return (
    <SectionComponent
      id='a-inter-invest-tem-o-investimento-ideal'
      SectionStyles='py-5 bg-white'
      minHeight={{ sm: 'auto' }}
    >
      <div className='col-12 col-md-6 col-xl-5'>
        <h2 className='fs-16 lh-20 fs-xl-20 lh-xl-25 fw-600 text-grayscale--500 mb-0'>Teleconsulta sem doer no bolso:</h2>
        <h2 className='fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 fw-600 text-grayscale--500 mb-3 mb-md-4 mb-lg-and-xl'><span className='d-block d-xl-inline'>Plano mensal</span> por <span className='text-orange--extra'>R$4,90</span> e consultas a preços acessíveis</h2>
        <p className='fs-14 lh-17 fs-lg-16 lh-lg-20 fs-xl-18 lh-xl-22 text-grayscale--400 mb-0 mb-md-4 mb-lg-and-xl'>Com o Doutor Inter você realiza consultas de pronto atendimento imediatamente ou agenda consultas com mais de 20 especialidades médicas.</p>
      </div>
      <div className='col-12 col-md-6 offset-xl-1'>
        <DefaultCarousel
          sm={{ items: 1 }}
          md={{ items: 1 }}
          lg={{ items: 1, partialVisibilityGutter: 85 }}
          xl={{ items: 1, partialVisibilityGutter: 181 }}
        >
          {
                cards.map((item: CardsType) => (
                  <Card key={item.icon} className='d-flex flex-column justify-content-between'>
                    <div>
                      <div className='mb-4'>
                        {icons[item.icon]}
                      </div>
                      <div className='text-block'>
                        <h3 className='fs-16 lh-20 text-grayscale--500 fw-600 mb-2' dangerouslySetInnerHTML={{ __html: item.title }} />
                        <p className='fs-14 lh-17 fs-md-16 lh-md-20 text-grayscale--400 fw-400 mb-0' dangerouslySetInnerHTML={{ __html: item.description }} />
                      </div>
                    </div>
                    <p className='fs-16 lh-20 text-grayscale--500 fw-600 mb-0 pt-2' dangerouslySetInnerHTML={{ __html: item.priceDescription }} />
                  </Card>
                ))
              }
        </DefaultCarousel>
      </div>
    </SectionComponent>
  )
}

export default PlanoMensal
