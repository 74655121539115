import { device } from 'src/styles/breakpoints'
import styled from 'styled-components'

export const Wrapper = styled.div`
  h1, h2, h3, h4 {
    font-family: 'Sora';
  }

  .btn {
    font-size: 14px !important;
    font-weight: 700 !important;

    max-width: auto;
    @media ${device.tablet} {
      max-width: 360px;
    }
  }

  .gatsby-image-wrapper {
    margin-bottom: 40px;
    @media ${device.tablet} {
      margin-bottom: 0;
    }
  }

  .mb-lg-and-xl {
    @media ${device.desktopLG} {
      margin-bottom: 40px !important;
    }
    @media ${device.desktopXL} {
      margin-bottom: 32px !important;
    }
  }
`
