import React from 'react'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'

import { SectionWrapper } from './style'

type DigitalAccountProps = {
  openModalOpenAccount: () => void;
  setIsOpen: Function;
  dataLayer: IDataLayerParams;
};

const Herobanner = ({ openModalOpenAccount, setIsOpen, dataLayer }: DigitalAccountProps) => {
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <SectionWrapper className='pb-5 pb-md-0'>
      <div className='container d-flex align-items-end align-items-md-center'>
        <div className='row align-items-center justify-content-center'>
          <div className='col-12 col-md-6 col-xl-5 offset-md-6 offset-xl-7 px-0 px-md-3'>
            <h1 className='fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 text-white fw-600 mb-3'>Doutor Inter: consultas online no seu Super App.</h1>
            <p className='fs-14 lh-17 fs-lg-16 lh-lg-20 fs-xl-18 lh-xl-22 text-white mb-3 pb-3'>Fila e burocracia para consultar? Nunca mais! Com Doutor Inter, você consulta com médicos especialistas, psicólogos e nutricionistas por meio da telemedicina, sem sair de casa.</p>

            <a
              href='https://intergo.app/dea87a79'
              target='_blank'
              rel='noreferrer'
              className='d-flex d-md-none align-items-center justify-content-center fs-14 lh-17 fw-700 text-orange--extra button-white mb-3 d-md-none'
              onClick={() => {
                sendDatalayerEvent({
                  section: 'dobra_01',
                  section_name: 'Doutor Inter: consultas online no seu Super App.',
                  element_action: 'click button',
                  element_name: 'Contratar agora',
                  redirect_url: 'https://intergo.app/dea87a79',
                })
              }}
            >
              Contratar agora
            </a>

            <a
              onClick={() => {
                setIsOpen(true)
                sendDatalayerEvent({
                  section: 'dobra_01',
                  section_name: 'Doutor Inter: consultas online no seu Super App.',
                  element_action: 'click button',
                  element_name: 'Contratar agora',
                })
              }}
              className='d-none d-md-flex align-items-center justify-content-center fs-14 lh-17 fw-700 text-orange--extra button-white mb-3 d-none d-md-block'
            >
              Contratar agora
            </a>

            <a
              href='https://intergo.app/f9430204'
              target='_blank'
              rel='noreferrer'
              className='d-flex d-md-none align-items-center justify-content-center fs-14 lh-17 fw-700 text-white text-white:hover button-transparent'
              onClick={() => {
                sendDatalayerEvent({
                  section: 'dobra_01',
                  section_name: 'Doutor Inter: consultas online no seu Super App.',
                  element_action: 'click button',
                  element_name: 'Abrir conta',
                  redirect_url: 'https://intergo.app/f9430204',
                })
              }}
            >
              Abrir conta
            </a>

            <a
              className='d-none d-md-flex align-items-center justify-content-center fs-14 lh-17 fw-700 text-white button-transparent'
              onClick={() => {
              openModalOpenAccount()
              sendDatalayerEvent(dataLayer)
              }}
            > Abrir conta
            </a>
          </div>
        </div>
      </div>
    </SectionWrapper>
  )
}

export default Herobanner
