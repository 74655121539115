import { device } from 'src/styles/breakpoints'
import { orange, white } from 'src/styles/colors'
import styled from 'styled-components'

import backgroundImageLG from '../../assets/images/agende-uma-consulta-online.png'

export const ContainerBackground = styled.div`
 @media ${device.desktopLG} {
   background-image: url(${backgroundImageLG});
   background-repeat: no-repeat;
    background-position: left bottom;
 }
`

export const ImgDiv = styled.div`
  .gatsby-image-wrapper {
    @media ${device.tablet} {
      margin: 0 auto;
    }
  }
`

export const Button = styled.div`
  @media ${device.desktopXL} {
    .btn {
      min-width: 456px !important;
    }
  }
`

export const OrangeCircle = styled.div`
  border-radius: 50%;
  font-size: 22px;
  background-color: ${orange.extra};
  width: 44px;
  height: 44px;
  color: ${white};
`
