import { device } from 'src/styles/breakpoints'
import { grayscale } from 'src/styles/colors'
import styled from 'styled-components'

export const Wrapper = styled.section`
  background-color: ${grayscale[500]};
  padding: 40px 0;

  @media ${device.desktopLG} {
    padding: 64px 0;
  }

  @media ${device.desktopXL} {
    padding: 96px 0;
  }

  a {
    padding-top: 13px;
    width: 100%;
    max-width: 100% !important;
  }
`
