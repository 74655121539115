import React from 'react'

// component
import FAQ from 'src/components/StructuredData/FAQ'

// style
import { FAQSection } from './style'

// json
import pageContext from '../../pageContext.json'

const FaqSection = () => {
  return (
    <FAQSection id='faq' className='bg-white pt-5'>
      <div className='container'>
        <div className='row'>
          <div className='col-12 mb-4 pb-2'>
            <h3 className='fs-24 lh-30 fs-lg-32 lh-lg-40 text-grayscale--500'>Perguntas frequentes</h3>
          </div>
          <FAQ columns={{ sm: 1, md: 1, lg: 1, xl: 1 }} styles='summary-content px-0' data={pageContext.structuredData.faq} />
        </div>
      </div>
    </FAQSection>
  )
}

export default FaqSection
