import { device } from 'src/styles/breakpoints'
import styled from 'styled-components'

export const Section = styled.section`

`
export const Button = styled.button`

`

export const Card = styled.div`
  .card-div {
    margin-bottom: 40px;
    @media ${device.tablet} {
      margin-bottom: 0;
    }
  }

  .gatsby-image-wrapper {
    @media ${device.tablet} {
      width: 216px !important;
      height: 216px !important;
    }
    @media ${device.desktopLG} {
      width: 296px !important;
      height: 296px !important;
    }
    @media ${device.desktopXL} {
      width: 262px !important;
      height: 262px !important;
    }
  }

  h3 {
    @media ${device.tablet} {
      height: 75px;
    }
  }
`
