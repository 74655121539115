import { useStaticQuery, graphql } from 'gatsby'

const PageQuery = () => {
  const pageQuery = useStaticQuery(graphql`
    query {
      bannerMuitoMaisQueUmBancoDigital: imageSharp(fluid: { originalName: { regex: "/banner-muito-mais-que-um-banco-digital/" }}) {
        fluid(maxWidth: 456, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      bannerContaInternacionalEmDolar: imageSharp(fluid: { originalName: { regex: "/banner-conta-internacional-em-dolar/" }}) {
        fluid(maxWidth: 456, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      bannerCartaoDeCreditoSemAnuidade: imageSharp(fluid: { originalName: { regex: "/banner-cartao-de-credito-sem-anuidade/" }}) {
        fluid(maxWidth: 456, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      bannerAInterInvestTemOInvestimentoIdeal: imageSharp(fluid: { originalName: { regex: "/banner-a-inter-invest-tem-o-investimento-ideal/" }}) {
        fluid(maxWidth: 456, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      bannerNoInterShop: imageSharp(fluid: { originalName: { regex: "/banner-no-inter-shop/" }}) {
        fluid(maxWidth: 456, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      bannerOInterEmpresas: imageSharp(fluid: { originalName: { regex: "/banner-o-inter-empresas/" }}) {
        fluid(maxWidth: 456, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      bannerNaoImportaOQueVocePrecisa: imageSharp(fluid: { originalName: { regex: "/banner-nao-importa-o-que-voce-precisa/" }}) {
        fluid(maxWidth: 456, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      bannerCreditoParaOQueVocePrecisar: imageSharp(fluid: { originalName: { regex: "/banner-credito-para-o-que-voce-precisar/" }}) {
        fluid(maxWidth: 456, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      imageDoutorInterFacilDeBuscar: imageSharp(fluid: { originalName: { regex: "/image-doutor-inter-facil-de-buscar/" }}) {
        fluid(maxWidth: 310, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      imageDoutorInterDescontosEmLaboratiorios: imageSharp(fluid: { originalName: { regex: "/image-doutor-inter-descontos-em-laboratorios/" }}) {
        fluid(maxWidth: 310, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      imageDoutorInterTudoSemSairDeCasa: imageSharp(fluid: { originalName: { regex: "/image-doutor-inter-tudo-sem-sair-de-casa/" }}) {
        fluid(maxWidth: 310, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      agendeUmaConsultaOnline: imageSharp(fluid: { originalName: { regex: "/agende-uma-consulta-online/" }}) {
        fluid(maxWidth: 438, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      blogCardsDoutorInter: imageSharp(fluid: { originalName: { regex: "/blog-cards-doutor-inter/" }}) {
        fluid(maxWidth: 360, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      blogCardsOQueETelemedicina: imageSharp(fluid: { originalName: { regex: "/blog-cards-o-que-e-telemedicina/" }}) {
        fluid(maxWidth: 360, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      blogCardsChegouOSuperApp: imageSharp(fluid: { originalName: { regex: "/blog-cards-chegou-o-super-app-do-inter/" }}) {
        fluid(maxWidth: 360, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      sectionDrInterBlackFriday: imageSharp(fluid: { originalName: { regex: "/image-doutor-inter-black-friday/" }}) {
        fluid(maxWidth: 360, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
  `)

  return pageQuery
}

export default PageQuery
