import React from 'react'

import { Card, NumBox, Line, TabletLineDiv } from './style'

type StepType = {
  title: string;
  description: string;
}
interface IStepper {
  data: StepType[];
}

const Stepper = ({ data }: IStepper) => {
  return (
    <div>
      <Line>
        <div className='d-flex'>
          <div className=''>
            {data.map((step: StepType, index: number) => (
              <TabletLineDiv className={`d-flex align-items-center mb-4 pb-3 mb-lg-0 pb-lg-0 card-container-${index + 1}`} key={step.title}>
                <NumBox className='d-flex justify-content-center align-items-center'>
                  <span className='fs-22 lh-26 fw-600 text-white'>{index + 1}</span>
                </NumBox>
                <Card className='d-flex align-items-center'>
                  <div className=''>
                    <h3 className='fs-16 lh-20 fs-lg-20 lh-lg-25 fw-600 text-grayscale--500'>{step.title}</h3>
                    <p className='fs-14 lh-17 fs-lg-16 lh-lg-20 text-grayscale--400'>{step.description}</p>
                  </div>
                </Card>
              </TabletLineDiv>
            ))}
          </div>
        </div>
      </Line>
    </div>
  )
}

export default Stepper
