import React, { useState } from 'react'
import { Modal } from 'src/components/Modal'
import useDomReady from 'src/hooks/window/useDomReady'
import { QrCodeModal } from 'src/components/Modal/QrCodeModal'
import OpenAccountRightForm from 'src/components/UI/Forms/OpenAccountRightForm'

import Layout from 'src/layouts/BaseLayout'
import {
  Herobanner,
  PlanoMensal,
  MuitasVantagensParaVoce,
  ComoAgendarUmaConsultaOnline,
  QuerSaberMaisSobreDoutorInter,
  FaqSection,
} from './section/_index'

// Style
import { Wrapper } from './style'
import pageContext from './pageContext.json'

import QRCodeDoutorInter from './assets/images/qrcode-drinter-correntistas.jpg'
import QRCodeDoutorInterNaoCorrentistas from './assets/images/qrcode-drinter-naocorrentistas.jpg'
import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'

const DoutorInterConsultaOnline = () => {
  const domReady = useDomReady()
  const [ modalOpenYourAccount, setModalOpenYourAccount ] = useState(false)
  const [ isOpen, setIsOpen ] = useState(false)

  const dataLayer: IDataLayerParams = ({
    section: 'dobra_01',
    section_name: 'Doutor Inter: consultas online no seu Super App.',
    element_action: 'click button',
    element_name: 'Abrir conta',
  })

  const handleClickOpenAccountModal = () => {
    setModalOpenYourAccount(!modalOpenYourAccount)
  }

  const openAccountModal = domReady && (
    <Modal
      isModalOpen={modalOpenYourAccount}
      setIsModalOpen={setModalOpenYourAccount}
      locationToRender={document.body}
    >
      {modalOpenYourAccount && (
        <OpenAccountRightForm
          closeModal={() => setModalOpenYourAccount(false)}
          formName='Formulario abertura de conta doutor-inter-consulta-online'
          qrBaixeApp={QRCodeDoutorInterNaoCorrentistas}
          dataLayer={dataLayer}
        />
      )}
    </Modal>
  )

  const qrCodeModal = domReady && (
    <Modal isModalOpen={isOpen} setIsModalOpen={setIsOpen} locationToRender={document.body}>
      <QrCodeModal
        isModal={isOpen}
        setIsModal={setIsOpen}
        title='Agora <span class="text-orange--extra">baixe nosso app</span> <br /> e abra sua conta gratuita.'
        instructions='Abra a câmera do seu celular e aponte para o QR Code acima. É só baixar o app do Inter e clicar em “Abrir conta completa e gratuita”. É rápido e fácil!'
        qrCode={QRCodeDoutorInter}
      />
    </Modal>
  )

  return (
    <Wrapper>
      <Layout pageContext={pageContext}>
        {openAccountModal}
        {qrCodeModal}
        <Herobanner
          openModalOpenAccount={handleClickOpenAccountModal}
          setIsOpen={setIsOpen}
          dataLayer={dataLayer}
        />
        <PlanoMensal />
        <MuitasVantagensParaVoce setIsOpen={setIsOpen} />
        <ComoAgendarUmaConsultaOnline setIsOpen={setIsOpen} />
        <QuerSaberMaisSobreDoutorInter />
        <FaqSection />
      </Layout>
    </Wrapper>
  )
}

export default DoutorInterConsultaOnline
